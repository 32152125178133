import React from 'react';

import { AiFillSetting, AiFillBell } from 'react-icons/ai';
import { FaBinoculars } from 'react-icons/fa';

const features = [
  {
    name: 'Installation',
    description:
      'Renseignez les marques et mots clés que vous souhaitez protéger ainsi que la zone géographique à couvrir.',
    icon: AiFillSetting,
  },
  {
    name: 'Surveillance',
    description:
      'TY Brand Safety analyse régulièrement les annonces visibles sur ces mots clés et vous alerte en cas de détection.',
    icon: FaBinoculars,
  },
  {
    name: 'Alertes',
    description:
      "Une fois alerté, TY Brand Safety vous apporte les informations nécessaires pour mettre en place un plan d'action : screenshot, réclamations auprès de Google, justificatif auprès de votre plateforme d'affiliation…",
    icon: AiFillBell,
  },
];

export default function Example() {
  return (
    <div className="py-12 bg-gray-50">
      <div className="text-center max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-3xl font-title font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Comment ça marche ?
        </h2>
        <dl className="mt-10 space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
          {features.map((feature, index) => (
            <div key={feature.name}>
              <dt>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-md bg-gray-700 text-white">
                  <feature.icon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-xl leading-6 font-medium text-gray-900">{feature.name}</p>
              </dt>
              <dd className="mt-2 text-base text-gray-500">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
